
import { useStore } from "vuex";
import { ref, defineComponent, onMounted, computed } from "vue";
import { Actions, Modules } from "@/store/enums/StoreEnums";
import GridEvents from "@/views/AllEventsGrid.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import NMEventDetailsModal from "@/views/EventDetailsModal.vue";

export default defineComponent({
  name: "events",
  components: {
    GridEvents,
    NMEventDetailsModal,
  },
  setup() {
    const store = useStore();

    let currentPageURL = ref("");

    let isLoading = computed(() => {
      return store.getters[Modules.EVENT + "getIsLoading"];
    });
    let events = computed(() => {
      return store.getters[Modules.EVENT + "getEvents"];
    });
    let eventsMeta = computed(() => {
      return store.getters[Modules.EVENT + "getMeta"];
    });

    onMounted(() => {
      setCurrentPageTitle("All Events");
    });

    fetchRecords();

    function gotoPage(url) {
      currentPageURL.value = url;
      fetchRecords();
    }

    function fetchRecords() {
      store.dispatch(Modules.EVENT + Actions.FETCH_EVENTS, {
        url: currentPageURL.value,
      });

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }

    return {
      isLoading,
      events,
      eventsMeta,
      gotoPage,
    };
  },
});
