
import { defineComponent, watch, nextTick } from "vue";
import { useStore } from "vuex";
// import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { Modules, Mutations } from "@/store/enums/StoreEnums";
import { formatDateTime } from "@/core/helpers/date";
import Pagination from "@/components/Pagination.vue";

export default defineComponent({
  name: "AllEvents",
  components: {
    Pagination,
    // Dropdown1,
  },
  props: {
    widgetClasses: String,
    events: Array,
    eventsMeta: Object,
    isLoading: Boolean,
    gotoPage: Function,
  },
  setup() {
    const store = useStore();

    const setEventDetailsIndex = (index) => {
      store.commit(Modules.EVENT + Mutations.SET_EVENT_DETAILS_INDEX, index);
    };
    return {
      formatDateTime,
      setEventDetailsIndex,
    };
  },
});
